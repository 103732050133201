import React from "react";
import getConfig from "next/config";
import { Avatar } from "@mui/material";

const { publicRuntimeConfig } = getConfig();

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name.name),
    },
    children: `${name.name.split(' ')[0][0]}${name.name.split(' ')[1][0]}`,
  };
}

const ReviewBox = ({ rate, name, company, text }) => {
  let ratebox = [];
  const numberOfStars = 5;
  for (let i = 1; i <= rate; i++) {
    ratebox.push(<div className={"star-box " + " rate" + rate} key={i}></div>);
  }

  for (let i = 1; i <= numberOfStars-rate; i++) {
    ratebox.push(<div className={"star-box " + " rate0"} key={i}></div>);
  }

  return (
    <div className="review-box">
      <div className="review-first-row">
        <div className="review-icon">
          <Avatar {...stringAvatar({name})} />
        </div>
        <div className="stars-wrapper">{ratebox}</div>
      </div>
      <div className="who-wrapper">
        <span>{name}</span><span className="review-text">reviewed</span><span>{company}</span>
      </div>
      <p>{text}</p>
    </div>
  );
};

export default ReviewBox;
