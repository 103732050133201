import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Typography from '@mui/material/Typography';

export default function SearchComponent({ value, path }) {
  const [search, setSearch] = useState('');
  const router = useRouter();

  function handleClick(event) {
    event.preventDefault();
    sessionStorage.setItem('freeSearchString', '');
    router.push({
      pathname: path,
      query: { text: search.trim() },
    });
  }
  function handleRegister(event) {
    event.preventDefault();
    router.push({
      pathname: '/trademark-registration',
    });
  }
  useEffect(() => {
    setSearch(value?.trim());
  }, [value]);
  return (
    <div className="top-banner">
      <div className="copy">
        <h1>
          File Your Trademark Online.
          <br />
          Official USPTO Registration process.
          <br />
          Protect Your Brand Today.
        </h1>
        <input
          type="text"
          placeholder="Search trademark availability"
          value={search || ''}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleClick(event);
            }
          }}
          data-hj-allow
        />
        <Typography
          className="orange-btn"
          style={{ cursor: 'pointer' }}
          onClick={handleClick}
        >
          Search
        </Typography>
        <Typography className="bottom">
          Already know your Trademark is available?
        </Typography>
        <Typography
          className="orange-btn"
          style={{
            cursor: 'pointer',
            maxWidth: 'fit-content',
            marginTop: '1em',
          }}
          onClick={handleRegister}
        >
          Start Registering Now
        </Typography>
      </div>
    </div>
  );
}
